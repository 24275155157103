<template>
    <div class="details-wrapper pt-0" v-if="page">
      <styleSidebar v-if="isVisible"  :key="ssb_key" @update_ssb="ssb_key++" :block="selected_block"/>
      <div class="container">
        <!-- insertion des "sections" et des conditions d'affichage -->
        <div class="row">
          <div class="col-md-12" >
            <div class="no-image my-5">
              <div class="d-flex justify-content-end" v-if="isVisible">
                <b-button  v-if="!modeEdit" class="btn btn-info my-4"  @click="modeEdit = true" >
                  <i class="fa fa-pencil"></i> 
                  <span v-if="page_name!=='blog'"> Editer la Page</span> 
                  <span v-else-if="page_name=='blog'"> Editer l'article</span> 
                </b-button>
              </div>
    
    
          <!-- <b-button  v-if="modeEdit && isVisible"  class="btn btn-info my-4"  @click="changeLog('z')" >
            <i class="fa fa-pencil"></i> Undo
          </b-button>
          <b-button v-if="modeEdit && isVisible" class="btn btn-info my-4" @click="changeLog('y')"  >
            <i class="fa fa-pencil"></i> Rendo
          </b-button> -->
          
          <div class="position-relative">
            <add-image
              :modal="'addImagePost'"
              @saveImage="saveImage(page, $event['ulid'])"
            />
            <div v-if = "!page[0]">
              <img class="img-fluid" style="width:100%" src="@/assets/images/import/default-image.jpg" alt="">
              <!-- <img src="../images/import/default-image.jpg" alt=""> -->
            </div>
            <picture v-else slot="img">
                <source :srcset="page[0].image.content.webpImage"  type="image/webp">
                <img class="img-fluid" :src="page[0].image.content.originalImage" alt="image du blog"/>
            </picture>
          </div>

          <card-pagination :class="{ selectSection: modeEdit }" v-for="(section, sectionId) in page" :key="sectionId" >
            <div v-if="modeEdit && isVisible" class="btn-group crud-section" role="group"  aria-label="crud-section"  >
              <b-dropdown  size="sm" variant="link"  class="btn btn-primary"  toggle-class="text-decoration-none"
                no-caret  title="Gérer les colonnes"  >
                <template #button-content>
                  <i class="mdi mdi mdi-view-column" style="color: #fff"></i>
                </template>
                <b-dropdown-item>
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button  type="button"  class="btn btn-outline-secondary"  @click="changeGrid(section.blocks, ['col-md-12'])">
                      <i class="mdi mdi-crop-portrait"></i>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" @click="changeGrid(section.blocks, ['col-md-4', 'col-md-8'])">
                      <i class="fa fa-columns"></i>
                    </button>
                    <button  type="button" class="btn btn-outline-secondary" @click="changeGrid(section.blocks, [ 'col-md-3',  'col-md-6',  'col-md-3',  ])">
                      <i class="mdi mdi-view-column"></i>
                    </button>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <button type="button" class="btn btn-primary"  @click="deleteSection('','','',section)" title="Supprimer"
              >
                <i class="mdi mdi-delete"></i>
              </button>
              <button type="button" class="btn btn-primary" title="Modifier">
                <i class="mdi mdi-pen"></i>
              </button>
              <!-- drag and drop -->
              <button type="button" class="btn btn-primary handle" style="cursor: move"  title="Déplacer"
              >
                <i class="mdi mdi-arrow-all"></i>
              </button>
            </div>
            <!-- si le contenu est vide -->
            <!-- <div v-if="section.blocks == 0" class="d-flex flex-column justify-content-center align-items-center">
              <h2 class="text-center text-muted">Contenu vide</h2>
              <img src="@/assets/images/import/db/no-data.png" style="width:35rem" alt="">
            </div> -->
            
            <div v-for="(blocks, blockIsd) in section.blocks"  class="col blocks" :class="blocks.column" :key="blockIsd" >
            <draggable :list="blocks.data" group="composant" :class="{drag:modeEdit}" >
              <div v-for="(block, blockId) in blocks.data" :key="blockId">
                <b-button  v-if="modeEdit && isVisible"  @click="removeComponent(blocks.data,block)">X</b-button>
                <b-button v-if="modeEdit && isVisible" v-b-toggle.sidebar-right  @click="selected_block =block ,ssb_key++"> <i class="fa fa-pencil" ></i> </b-button>

                <component
                  :is="block.element"
                  v-bind="_properties(block.element, block, section)"
                  v-dynamic-events:[{block:block,data:block.data,section:section}]="
                    Allevent[block.element]
                  "
                  :key="blockId+''+ssb_key"
                >
                </component>
              </div>
            </draggable>
            </div>
            <add-empty-section
                v-if="modeEdit"
                :ulid="section.ulid"
                @addEmpty="
                  sectionAddEmpty(
                    page,
                    section,
                    $event['section'],
                    $event['blocks']
                  )
                "
              ></add-empty-section>
          </card-pagination>
       </div>
      </div>
    </div>
    <div v-if="editable">
      <button  class="btn btn-success" v-if="page_name !=='blog'" @click="putPage()">Enregistrer les modifications</button>
      <button  class="btn btn-success" v-else-if="page_name =='blog'" @click="putBlog()">Enregistrer l'article</button>
    </div>
    </div>
  </div>
</template>
<script>
import addImage from '../../../../components/functions/add-image.vue'
// import pageJson from "../../json/section-edena-data.json";
import addEmptySection from '../../../../components/functions/add-empty-section.vue';
import draggable from "vuedraggable";
import event from "../preview/event-list.json";
import { d_properties } from "../preview/properties.js";
// importation des methods
// import {getSectionPage, editBanner,changePicture,editCard,carouselNewSlide,carouselMebNewSlide,NewCardMeb,textRemove,textPush,cardSave,cardProductSave,savePageEdit,deleteSection,changeGrid,removeComponent,loadLog,saveLog,cancelAutoUpdate,changeLog,changeText,changeTextHorizontalBanner,ChangeCarrierePr,changeCurImage,changeImageOriginalWebp,changeTextWithElement} from "../preview/method.js";
import { methodsMixin } from '../preview/mixins/methodsMixin.js'

import styleSidebar from '../../../../components/style-sidebar.vue';


import CardPagination from '../../../../components/pagination/card-pagination.vue';
import axios from 'axios';


export default {
  name: "preview",
  mixins:[methodsMixin],
  components: {
    addImage,
    "carousel": () => import("../../../../components/element/carousel.vue"),
    "carousel-meb": () =>import("../../../../components/element/carousel-meb.vue"),
    "horizontal-banner": () =>import("../../../../components/element/horizontal-banner.vue"),
    "product-card": () =>import("../../../../components/element/product-card.vue"),
    "circle-card-meb": () =>import("../../../../components/element/circle-card-meb.vue"),
    "card-title-description": () =>import("../../../../components/element/card-title-description.vue"),
    "gold-card-meb": () =>import("../../../../components/element/gold-card-meb.vue"),
    "meb-biographie": () =>import("../../../../components/element/meb/meb-biographie.vue"),
    "stringtext": () => import("../../../../components/element/string-text.vue"),
    "textType1": () => import("../../../../components/element/text-type-1.vue"),
    "shop-meb": () => import("../../../../components/element/shop-meb.vue"),
    "services-meb": () => import("../../../../components/element/services-meb.vue"),
    "product-details": () =>import("../../../../components/element/product-details.vue"),
    "profil-pr": () =>import("../../../../components/element/profil-pr.vue"),
    "carriere-pr": () =>import("../../../../components/element/carriere-pr.vue"),
    "blue-and-orange-card-pr": () =>import("../../../../components/element/blue-and-orange-card-pr.vue"),
    "text-tt": () =>import("../../../../components/element/text-tt.vue"),
    // Mon échappée belle
    "meb-carousel": () => import("../../../../components/element/meb/meb-carousel.vue"),
    // Template
    "cpts-accueil": () => import("../../../../components/page/cpts-accueil.vue"),
    CardPagination,
    addEmptySection,
    styleSidebar,
    draggable
  },
  //https://fr.vuejs.org/v2/guide/custom-directive.html
  directives: {
    DynamicEvents: {
      bind: (el, binding, vnode) => {
        const allEvents = binding.value;
        Object.keys(allEvents).forEach((event) => {
          vnode.componentInstance.$on(event, (eventData) => {
            const targetEvent = allEvents[event];
            vnode.context[targetEvent](
              eventData,
              binding.arg.data,
              binding.arg.block,
              binding.arg.section
            );
          });
        });
      },
      unbind: function (el, binding, vnode) {
        vnode.componentInstance.$off();
      },
    },
  },
  data() {
    return {
      page: [],
      modeEdit: false,
      ssb_key:1,
      selected_block:{},
      Allevent: event,
      dkey:1,
      type:"section"
    };
  },
  props:{
    // ulid de la page ou du POSTS/BLOG/ARTICLE
    ulid:{
      type:String,
      default:""
    },
    // nom de la page
    page_name:{
      type:String,
      default:""
    },
    editable:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    isVisible(){
      var isfund = false
      if (this.$route.name == 'test' || this.$route.name == 'test2'|| this.$route.name == 'test3' || this.$route.name=='page-preview'){
        isfund = true
      }
      return isfund
    }
  },
  methods: {
    _properties: d_properties,

    //registration methode
    // getSectionPage,
    // editBanner,
    // changePicture,
    // editCard,
    // carouselNewSlide,
    // carouselMebNewSlide,
    // NewCardMeb,
    // textRemove,
    // textPush,
    // cardSave,
    // cardProductSave,
    // savePageEdit,
    // deleteSection,
    // changeGrid,
    // removeComponent,
    // loadLog,
    // saveLog,
    // cancelAutoUpdate,
    // changeLog,
    // changeText,
    // ChangeCarrierePr,
    // // HorizontalBanner
    // changeTextHorizontalBanner,
    // // Biographie-meb
    // changeCurImage,
    // changeImageOriginalWebp,
    // changeTextWithElement,



    sectionAddEmpty(page, section, data, blocks) {
      // ajout dynamique de la section
      let order = 0;
      if (section == 0) {
        order = 1;
      } else {
        order = page.indexOf(section) + 1;
      }
      page.splice(order, 0, {
        page: this.ulid,
        title: (Math.random() + 1).toString(36).substring(7),
        sectionorder: "" + order + "",
        blocks,
      });
    },

    // enregistrement de la page à partir de la section
    async putPage(){
        this.modeEdit = false;
      for (let index = 0; index < this.page.length; index++) {
        // console.log(this.page[index]);
        if(this.page[index].ulid){
        // if(this.page[index].hasOwnProperty('ulid')){
          await axios.put(`sections/${this.page[index].ulid}`,{
            page:this.$route.params.ulid,
            sectionorder:''+index+'',
            blocks: this.page[index].blocks
          })
          .then(resPutPage=>{
            console.log(resPutPage)
          })
          .catch(errPutPage=>console.error(errPutPage))
        }
        else{
         axios.post('sections',{
          blocks:this.page[index].blocks,
          page:this.$route.params.ulid,
          title:"section page "+this.$route.params.ulid+" "+index,
          slugname:this.$route.params.ulid+"_"+index,
          sectionorder:''+index+''
         }, {
            headers: {
              "X-AUTH-TOKEN": localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          })
        }
      }
    },
    // si la modification est un blog/article/posts
    putBlog(){
      let blocks = []
      for(let section of this.page){
        for(let block of section.blocks){
          blocks.push(block)
        }
      }
          let body = {
            blocks:blocks
          }
      console.log({body});
      axios.put(`posts/${this.ulid}`,body)
      .then(resPutBlog => {
        console.log(resPutBlog)
        this.$router.push({
            name:'postDetails',
            params:{
                ulid:this.ulid
            }
        })
      })
      .catch(errPutBlog => errPutBlog)
    },
    // modification image blog
    saveImage(post,ulidImage,modalId){
      post
      modalId
      this.showpageLoader = true
      console.log(ulidImage);
      axios.put(`posts/${this.ulid}`, {
          image: ulidImage,
          })
          .then(()=> {
              this.$bvModal.hide(modalId)
              // this.getPosts(this.ulid)
          })
          .catch(err => console.log ('errorPUT', err))
      },
  },
  watch:{
    selected_block(val){
      console.log({val})
    }
  },
  created(){
    // this.getSectionPage(this.$route.params.ulid);

    // this.page_name!=="blog"?this.getSectionPage(this.ulid):this.getPosts(this.ulid)
    this.page_name!=="blog"?this.getSectionPage(this.ulid):this.getPosts(this.ulid)
  }
};
</script>

<style lang="scss" scoped>
// css MEB
// @import url("./../../../assets/scss/monechappeebelle.scss");
// css PR
@import url("./../../../../assets/scss/pr.scss");

//page-preview
.selectSection:hover {
  border: 1px blue solid;
  padding-top: 3rem;
}
.selectSection {
  border-bottom: 1px solid #b66dff;
  padding-top: 3rem;
}
.crud-section {
  position: absolute;
  top: -2rem;
  right: 4rem;
  margin-top: -16px;
  margin-right: -65px;
}
.drag {
  border: 1.5px dashed #bbbbbb;
  width: 100%;
  min-height: 10rem;
  // height: 200px;
}
.crud-section {
  height: 2rem;
  display: none;
}

.crud-section button,
.crud-section .b-dropdown {
  padding: 0px 40px;
}

.selectSection:hover .crud-section {
  display: inherit;
}
</style>

<style>
@import url('https://use.typekit.net/xkb8fqx.css');
</style>