export let refreshAfterInactivityMixin = {
    data() {
        return {
          inactivityTimer: null, // Variable pour le minuteur d'inactivité
        };
      },
      methods: {
        // Fonction pour réinitialiser le minuteur d'inactivité
        resetInactivityTimer() {
          clearTimeout(this.inactivityTimer); // Effacez le minuteur existant
          this.inactivityTimer = setTimeout(() => {
            // Rafraîchissez la page après 30 minutes d'inactivité
            location.reload();
            }, 30 * 60 * 1000); // 30 minutes en millisecondes
        //   }, 30000); // 30 secondes
        },
      },
      mounted() {
        // Ajoutez des gestionnaires d'événements pour détecter l'activité de l'utilisateur
        window.addEventListener('mousemove', this.resetInactivityTimer);
        window.addEventListener('keydown', this.resetInactivityTimer);
        window.addEventListener('click', this.resetInactivityTimer);
    
        // Démarrez le minuteur d'inactivité initial
        this.resetInactivityTimer();
      },
      beforeDestroy() {
        // Supprimez les gestionnaires d'événements lorsque le composant est détruit
        window.removeEventListener('mousemove', this.resetInactivityTimer);
        window.removeEventListener('keydown', this.resetInactivityTimer);
        window.removeEventListener('click', this.resetInactivityTimer);
      }
}