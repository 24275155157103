import axios from 'axios'

const notifications = {
    state:{
        notifications:[]
    },
    getters:{

    },
    mutations:{
        GET_NOTIFICATIONS(state, notifications){
            state.notifications = notifications
        }
    },
    actions:{
        getNotifications(context){
            axios.get('notifications?orderby_date=1')
            .then(resGetNotifications => {
                context.commit('GET_NOTIFICATIONS', resGetNotifications.data.result)
            })
            .catch(errGetNotifications => errGetNotifications)
        }
    }
}

export default notifications