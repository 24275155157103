<template>
    <section>
        <!-- activation -->
        <!-- <b-button v-b-toggle.sidebar-right>modification style</b-button> -->
        <!-- sidebar -->

        <b-sidebar id="sidebar-right" right shadow>
            <!-- footer -->
            <template v-if="block.data" class="no-padding" #footer="{}">
            
                <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                    <strong class="mr-auto">
                        <i class="fa fa-home mx-2"></i>
                        <i class="fa fa-home mx-2"></i>
                        <i class="fa fa-home mx-2"></i>
                        <i class="fa fa-home mx-2"></i>
                    </strong>
                    <b-button size="sm" @click="save()">Enregistrer</b-button>
                </div>
            </template>
            <!-- contenu du sidebar -->
            <div v-if="block.data" class="px-3 py-2">
                <b-dropdown id="ddown12" v-if="block.data" :text="block.element" variant="outline-info">
                    <b-dropdown-item>Settings</b-dropdown-item>
                </b-dropdown>
                <b-tabs content-class="mt-3">
                    
                    <!-- tabs-Style -->
                    <b-tab title="style" active>
                        <h6>Arrière-plan</h6>
                        <hr>
                        <!-- background-color -->
                        <b-form-group label="Couleur"  label-for="input9">
                            <input class="pointer" type="color" id="head" name="head" v-model='style.color'>
                            <label class="mx-4" for="head">{{style.backgroundColor}}</label>
                        </b-form-group>
                        <!-- background-image -->
                        <b-form-group label="Image"  label-for="input9">
                            <div class="d-flex">
                                <add-image 
                                :modal="'Ajout image'"
                                @saveImage="block.image = $event['image'],update" 
                                />
                                <!-- <label class="mx-4" for="head">{{block.data[0].image}}</label> -->
                            </div>
                        </b-form-group>

                        <!-- <h6>Texte</h6>
                        <div v-if="block.data.text">
                        <div v-if="editor">
                            <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                            bold
                            </button>
                            <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                            italic
                            </button>
                            <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                            strike
                            </button>
                            <button @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
                            code
                            </button>
                            <button @click="editor.chain().focus().unsetAllMarks().run()">
                            clear marks
                            </button>
                            <button @click="editor.chain().focus().clearNodes().run()">
                            clear nodes
                            </button>
                            <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
                            paragraph
                            </button>
                            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                            h1
                            </button>
                            <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                            h2
                            </button>
                            <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                            h3
                            </button>
                            <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
                            h4
                            </button>
                            <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
                            h5
                            </button>
                            <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
                            h6
                            </button>
                            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                            bullet list
                            </button>
                            <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                            ordered list
                            </button>
                            <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
                            code block
                            </button>
                            <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
                            blockquote
                            </button>
                            <button @click="editor.chain().focus().setHorizontalRule().run()">
                            horizontal rule
                            </button>
                            <button @click="editor.chain().focus().setHardBreak().run()">
                            hard break
                            </button>
                            <button @click="editor.chain().focus().undo().run()">
                            undo
                            </button>
                            <button @click="editor.chain().focus().redo().run()">
                            redo
                            </button>
                        </div>
                        <editor-content :editor="editor" />

                        </div> -->
                        <hr>
                        <!-- text-color -->
                        <b-form-group label="Couleur"  label-for="input9">
                            <input class="pointer" type="color" id="head" name="head" v-model='style.textColor'>
                            <label class="mx-4" for="head">{{style.textColor}}</label>
                        </b-form-group>
                        <!-- text-size -->
                        <b-form-group label="Taille"  label-for="input9">
                            <b-form-select placeholder="taille">
                                
                            </b-form-select>
                            <label class="mx-4" for="head">{{style.textSize}}</label>
                        </b-form-group>

                    </b-tab>
                    <!-- Contenu -tabs -->
                    <b-tab title="contenu">
                        <h6>Eléments à afficher (card)</h6>
                        <hr>
                        <b-form-checkbox
                            id="description"
                            v-model="content.card.description"
                            name="description"
                            >
                            Description
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="title"
                            v-model="content.card.title"
                            name="title"
                            >
                            titre
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="price"
                            v-model="content.card.price"
                            name="prix"
                            >
                            prix
                        </b-form-checkbox>
                    </b-tab>
                    
                    <b-tab title="animation">
                        <h6>Animation au scroll</h6>
                        <hr>
                        <!-- animation -->
                        <b-form-group label="Type d'effet"  label-for="input9">
                            <b-form-select class="pointer" type="text" id="head" name="head" :options="params.animation.type" v-model='animation.type'/>
                            <label class="mx-4 text-muted" for="head">{{animation.type}}</label>
                        </b-form-group>
                        <b-form-group label="Durée (sec)"  label-for="input9">
                            <b-input class="pointer" type="number" id="head" name="head" v-model='animation.duration' max="10"/>
                            <label class="mx-4 text-muted" for="head">{{animation.duration}}</label>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
            </div>
            
            
        </b-sidebar>
    </section>
</template>
<script>
import addImage from "./functions/add-image.vue";
import params from "./json/style-parameters.json";
import StarterKit from '@tiptap/starter-kit'
import { Editor } from '@tiptap/vue-2'
export default {
  name: "style-sidebar",
  components: {
    addImage,
  },
  props: {
    block: Object,
  },
  data() {
    return {
      editor: null,
      params: params,
      selectedElement: {},
      style: {
        backgroundColor: "#e66465",
        textColor: "#e66465",
        image: "url",
      },
      content: {
        card: {
          description: true,
          title: true,
          price: true,
        },
      },
      animation: {
        type: "none",
        duration: "000",
      },
    };
  },
  methods: {
    sidebar() {
      console.log(this);
    },
    save() {
      this.$emit("saveStyle", {
        backgroundColor: this.style.backgroundColor,
        backgroundImage: this.style.image,
        textColor: this.style.textColor,
        animationType: this.animation.type,
        animationDuration: this.animation.duration * 1000,
      });
    },
    saveImage(payload, elem) {
      console.log(elem);
      elem = payload.image;
    },
    update() {
      this.$emit("update_ssb");
    },
  },
  mounted() {
    console.log("valeur par défaut", this.defaultValue);
    console.log(this.block.data.text);
    this.editor = new Editor({
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        this.block.data.text = this.editor.getHTML()
      },
      content:this.block.data.text,
    })

  },
};
</script>
<style lang="scss" scoped>
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
</style>