<template>
<div>
  <section class="main-view">
    <!-- pageLoader -->
    <!-- <pageLoader /> -->
    
    <div class="container-scroller">
      <Header/>
      <div class="container-fluid page-body-wrapper">
        
        <Sidebar/>
        <vue-snotify></vue-snotify>
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view></router-view>
            <!-- <componentsSidebar/> -->
          </div> <!-- content wrapper ends -->
          <Footer/>
        </div> <!-- main panel ends -->
      </div> <!-- page-body-wrapper ends -->
    </div>
  </section>
</div>
</template>

<script>
  import Header from "./partials/Header";
  import Sidebar from "./partials/Sidebar";
  import Footer from "./partials/Footer";
  import axios from 'axios';
  import {mapState} from 'vuex'
  import { refreshAfterInactivityMixin } from "../../../pages/dashboard/mixins/refresh_after_inactivity";
  export default {
    name: "layout",
    mixins: [refreshAfterInactivityMixin],
    components: {
      Header,
      Sidebar,
      Footer,
      // componentsSidebar
      // unauthentified,
      // pageLoader
    },
    data () {
      return {
        isHidden: false,
        logged: null
      }
    },
    computed:{
      ...mapState({
            main_api_url: state=> state.globalVariable.main_api_url
        }),
      isVisible(){
        var isfund = false
        if (this.$route.name == 'test' || this.$route.name == 'test2'|| this.$route.name == 'test3' || this.$route.name=='page-preview'){
          isfund = true
        }
        return isfund
      }
    },
    methods: {
      getMyInfos(){
        axios.get(`${this.main_api_url}/myinfos`)
        .then(resGetMyInfos => {
          localStorage.setItem(`user_ulid`, resGetMyInfos.data.ulid)
        })
        .catch(errGetMyInfos =>{
          if(errGetMyInfos.response.data.status === 403 || errGetMyInfos.response.data.status === 401) {
            this.$swal({
              title: 'Votre session est expirée, se reconnecter',
              animation: false,
              confirmButtonColor: '#3c8462',
            })
            localStorage.removeItem('token')
            localStorage.removeItem('pseudo')
            this.$router.push({name:'authentification'})
          }
          console.log({errGetMyInfos});
        })
      },
    },
    beforeCreate(){
      delete axios.defaults.headers.common['X-AUTH-TOKEN'];
    },
    created () {
      // this.logged= localStorage.getItem('token')
      this.$store.commit('GET_USER')
    },
    mounted() {
      this.getMyInfos()
    }
  }
</script>