<template>
	<section>
		<div class="page-loader  row" v-if="!isloaded">
			<span class="col-md-12 pr-0 d-flex justify-content-center">
				<div class="yellow"></div>
				<div class="red"></div>
				<img class="imageRotateHorizontal mx-5" src="@/assets/images/logo_feuille_tricolore.png" alt="">
				<div class="blue"></div>
				<div class="violet"></div>
			</span>
			<span class="col-md-12 d-flex justify-content-center">
				<h4 class="text-muted">{{loadingText}}</h4>
			</span>
		</div>
	</section>
</template>

<script>
export default ({
    data () {
        return {
            isloaded: false
        }
    },
	props: {
		loadingText: {
		type:String,
		default: "Traitement des données"
		}
	},
    mounted () {
        // document.onreadystatechange = () => {
        //     if(document.readyState == "complete") {
        //         this.isloaded = true;
        //     }
        // }
    }
})
</script>

<style lang="scss" scoped>
:root {
	--yellow: #b8985e;
	--red: #b8985e;
	--blue: #b8985e;
	--violet: #b8985e;
}
.page-loader {
    display: flex;
    justify-content: center ;
    align-items: center;
    position: fixed;  
    top: 0;
    left: 0;
    width: 110vw;
    height: 100vh;
    // background-color: #fff;
	background: rgba(255,255,255,0.8);
    z-index: 1055;
}

// div.container {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// }
span > div {
	width: 2vw;
	height: 2vw;
	border-radius: 100%;
	margin: 2vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}
.yellow {
	background-color: #94c133;
}

.red {
	background-color: #398264;
	animation-delay: 0.1s;
}

.blue {
	background-color: #398264;
	animation-delay: 0.2s;
}

.violet {
	
	background-color: #94c133;
	animation-delay: 0.3s;
}

@keyframes bounce {
	0%, 50%, 100% {
		transform: scale(1);
		filter: blur(0px);
	}
	25% {
		transform: scale(0.6);
		filter: blur(3px);
	}
	75% {
		filter: blur(3px);
		transform: scale(1.4);
	}
}
// image et animation
img {
	width: 4rem;
}
.imageRotateHorizontal{
    -moz-animation: spinHorizontal .9s infinite linear;
    -o-animation: spinHorizontal .9s infinite linear;    
    -webkit-animation: spinHorizontal .9s infinite linear;
    animation: spinHorizontal .9s infinite linear;
}

@keyframes spinHorizontal {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
}

// animation texte
.animated-text {
	animation: bounce2 3s 0.5s linear infinite;
}
@keyframes bounce2 {
	0%, 50%, 100% {
		transform: scale(1);
		filter: blur(0px);
	}
	25% {
		transform: scale(0.6);
		filter: blur(3px);
	}
	75% {
		filter: blur(3px);
		transform: scale(1.2);
	}
}

</style>
