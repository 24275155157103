<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <a href="javascript:void(0);" class="nav-link">
            <div class="nav-profile-image">
              <span v-if="$store.state.authentification.webpImage && $store.state.authentification.originalImage">
                <picture>
                  <source class="img-lg rounded-circle mb-3"  :srcset="$store.state.authentification.webpImage" type="image/webp">
                  <img id="profil" class="" :src="$store.state.authentification.originalImage" alt="image" />
                </picture>
              </span>
              <img v-else src="@/assets/images/faces/face1.jpg" alt="profile">
              <span class="login-status online"></span>
            </div>
            <div class="nav-profile-text d-flex flex-column">
              <router-link to="/profil" class="profil">
                <span class="font-weight-bold mb-2">{{$store.state.authentification.pseudo}}</span>
              </router-link>
              
              <span class="text-secondary text-small">{{$store.state.authentification.pseudo}}</span>
            </div>
            <i class="mdi mdi-bookmark-check text-primary nav-profile-badge"></i>
          </a>
        </li>

        <!-- profil -->
        <!-- <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/profil">
            <span class="menu-title">Mon profil</span>
            <i class="mdi mdi-face-profile  menu-icon"></i>
          </router-link>
        </li> -->

        <!-- Mes abonnements -->
        <!-- <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/subscription">
            <span class="menu-title">Mes abonnements</span>
            <i class="mdi mdi-format-list-bulleted  menu-icon"></i>
          </router-link>
        </li> -->

        <!-- Mes applications -->
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/applications">
            <span class="menu-title">Mes applications</span>
            <i class="mdi mdi-cast   menu-icon"></i>
          </router-link>
        </li>

       
        <!-- logout -->
        <li class="nav-item mobile mt-2 pointer" @click="logout()">
            <span class="menu-title">Se déconnecter</span>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
// import siteApp from '../../pages/sitesApp'
const axios = require('axios').default;
import {mapState, mapGetters} from 'vuex'

export default {
  name: 'sidebar',
 data () {
    return {
      roles:'TS',
      collapses: [ 
     { show: false },
     { show: false },
     { show: false },
    ],
    section: [],
    componentsMenu: true,
    sites:[
      {  id:"test", name: "ailem_1", backendurl:"https://api.ailem.io" },
      {  id:"test2", name: "ailem_2", backendurl:"https://api2.ailem.io" }
    ]
    }
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    logout() {
      this.$swal({
        title: "Se déconnecter",
        text: "Voulez-vous quitter l'application?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Se déconnecter',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {

        if (result.value) {
          localStorage.removeItem('token')
          localStorage.removeItem('pseudo')
          this.$router.push({name:'authentification'})
        }
      })
    },
    getMySites(){
        axios.get(`${this.main_api_url}/mysites`, { withCredentials:true })
        .then(resGetMySites => {
            this.sites = resGetMySites.data.result
        })
        .catch(errGetMysites => {
          console.error(errGetMysites)
          console.log("erreur get");
        } )
    },
    gotoApplications(){
      this.$router.push({
        name: "applications"
      })
    },
    // avadika Vuex miaraka @ ilay any @ page applications @ manaraka
    async select(item){
            localStorage.setItem('backend', item.backendurl)
            localStorage.setItem('open_site', item.name)
            let backend = await localStorage.getItem('backend', item.backendurl)

            await this.$store.commit('setBackend', backend)

            // authentification utilisant le OpenIdConnect
            axios.post(`${backend}/oiconnect`,{
                refresh_token: localStorage.getItem('refresh_token')
            })
            .then(resOiconnect => {
                this.login(resOiconnect.data.link)
                location.reload()
            })
            .catch(errOiConnect => {
                console.log({errOiConnect});
            })
            
        },
    open_site(){
      return localStorage.getItem('open_site')
    }
  },
  mounted () {
    this.getMySites()

    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem    while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch:{
    $route () {
       document.querySelector('#sidebar').classList.toggle('active');
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapState(['user']),
    ...mapState({
          main_api_url: state=> state.globalVariable.main_api_url
    }),
    isVisible(){
      var isfund = false
      if (this.$route.name == 'test' || this.$route.name == 'test2'|| this.$route.name == 'test3' || this.$route.name=='page-preview'){
        isfund = true
      }
      return isfund
    }
  }

}
</script>

<style scoped>
a:hover {
  color:inherit;
  text-decoration: none;
}
.profil {
  color:#3e4b5b
}
:deep .dropdown-menu {
  width: 100%;
}
</style>