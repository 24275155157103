/*
* On stock les données centralisée avec Vuex
*
* Regroupement par modules Documentation:
* "https://vuex.vuejs.org/guide/modules.html"
*/
import Vuex from "vuex";
import Vue from'vue'
//importation des modules
import authentification from "./modules/authentification";
import produits from "./modules/produits";
import globalSearch from "./modules/global-search";
import users from "./modules/users";
import notifications from "./modules/notifications";
import globalVariable from "./modules/global-variable";

Vue.use(Vuex)

const store = () => {
  return new Vuex.Store({
    modules: {
        authentification: authentification,
        produits: produits,
        globalSearch: globalSearch,
        users: users,
        notifications: notifications,
        globalVariable: globalVariable
    }
  });
};

export default store;