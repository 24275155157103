<template>
    <section>
        <b-badge @click="addSection()" class="add-section" pill variant="primary" title="Ajouter une section"><i class="mdi mdi-plus"></i></b-badge>
    </section>
</template>
<script>
export default {
    name: "add-empty-section",
    data () {
        return {

        }
    },
    props: {
    },
    methods: {
        addSection() {
            this.$emit('addEmpty', {
                section : {
                    "title" : "test",
                    "blocks": []
                },
                blocks: [
                    {
                    "column":"col-md-12",
                    "data":[
                            {
                               
                            }
                        ]
                    }
                ]
             })
        }
    }
}
</script>
<style scoped>
.add-section{
  /* height: 2rem; */
  /* width: 2rem; */
  border-radius: 50%;
  position: absolute;
  left: -1rem;
  bottom: -1rem;
  /* padding-top: 0px; */
  font-size: 24px;
}

</style>