<template>
    <section class="card-pagination row">
        <div class="col-md-12">
            <!-- <div class="page-header">
                <h3 class="page-title">
                {{title}}
                </h3>
            </div> -->
            <div class="row mb-5" >
                <!-- <div class="scrollcards" :id="id"> -->
                    <slot>
                    </slot>
                <!-- </div> -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'card-pagination',
    data () {
        return {
        }
    },
    props: {
        title: String,
        id: String,
        tooltip: Boolean
    },
    methods: {
        scrollright() {
            document.getElementById(`${this.id}`).scrollLeft += 1150;
        },
        scrollleft() {
            document.getElementById(`${this.id}`).scrollLeft -= 1150;
        }
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
} 
.scrollcards {
  /* background-color: #fff; */
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

</style>