// import router from '../../../router/index'
const authentification = {
    state:{
        token:null,
        pseudo:null,
        isAuthenticated: false,
        webpImage:null,
        originalImage:null,
        roles:'',
        refresh_token:'',
        login_info:{}
    },
    mutations:{
        authUser(state,userData){
            state.token= userData.token,
            state.pseudo = userData.pseudo,
            state.roles = userData.roles,
            state.originalImage = userData.originalImage,
            state.webpImage = userData.webpImage,
            state.isAuthenticated = userData.isAuthenticated
        },
        clearAuth(state){
            state.pseudo = null,
            state.token = null,
            state.isAuthenticated =false,
            state.originalImage = null,
            state.webpImage = null,
            state.refresh_token = null
        },
        setRefreshToken(state, refresh_token){
            state.refresh_token = refresh_token
        },
        setLoginInfo(state, login_info){
            state.login_info = login_info
        }
    },
    actions:{
        login({commit},Auth){
            const resGet = Auth.resmyinfo
            const res = Auth.reslogin
            //Enregistrer l'utilisateur dans authUser
            let originalImage = ""
            let webpImage = ""
            
            if (resGet.data.image) {
                originalImage=resGet.data.image.content.originalImage
                webpImage=resGet.data.image.content.webpImage
            }
            commit('authUser', {
                token: res.data.token,
                pseudo: res.data.pseudo,
                roles: res.data.roles,
                isAuthenticated : true,
                originalImage:originalImage,
                webpImage: webpImage,
            })
            //Utiliser local storage
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('pseudo', res.data.pseudo)
            //date d'expiration de local storage
            localStorage.setItem('expiresIn', res.data.expires)
            //redirection après l'enregistrement

            localStorage.setItem('originalImage', originalImage)
            localStorage.setItem('webpImage', webpImage)

            window.location.reload()
            // router.replace('/dashboard')
                
        },
        logout({commit}){
            commit('clearAuth')
            // Définir la date d'expiration du cookie à une date passée
            document.cookie = `ailem.io=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
            console.log("logout activé, ce console log est dans le store authentification");
            // router.push('/')
        },
        //Charger dans localstorage
        AutoLogin({commit}){ 
            const token = localStorage.getItem('token')
            if (!token) {
                // localStorage.clear()
                commit('clearAuth')
                return
            }
            const date_dexpiration = localStorage.getItem('expiresIn')
            const now = new Date()
            const exp = new Date(date_dexpiration)
            console.log(now <= exp)
            //time zone different de serveur 
            /*if(now <= exp){
                //vider localstorage
                localStorage.clear()
                commit('clearAuth')
                return
            }*/
            const pseudo =localStorage.getItem('pseudo')
            const originalImage = localStorage.getItem('originalImage')
            const webpImage = localStorage.getItem('webpImage')
            commit('authUser',{
                token:token,
                pseudo:pseudo,
                isAuthenticated:true,
                originalImage: originalImage,
                webpImage: webpImage
            })
        },
        //expiration 
        AutoLogout(){

        },
    },
    getters:{
        getAuth(state) {
            return state.authentification= state.isAuthenticated
        }
    }
}

export default authentification;