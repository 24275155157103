// import axios from 'axios'   
const globalVariable = {
    state: {
        main_api_url : process.env.VUE_APP_BE
        // main_api_url : "https://api.ailem.io"
    },
    getters:{

    },
    mutations:{
        SET_CLIENT_API(state, payload){
            state.client_api_url = payload
        },
        // SET_MAIN_API(state) {
        //     process.env.NODE_ENV === "development"
        //     ?state.main_api_url = "http://localhost:8000"
        //     :state.main_api_url = "http://api.ailem.io"
        // }
    },
    actions:{

    }
}
export default globalVariable