import axios from "axios"

const users = {
    state: {
        users:[]
    },
    getters: {

    },
    mutations: {
        GET_USERS(state, users) {
            state.users = users
        }
    },
    actions: {
        getUsers(context){
            axios.get('api/users',{
                headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }
            })
            .then(resGetUsers => {
                context.commit('GET_USERS', resGetUsers.data.result)
            })
            .catch(errGetUsers=> console.err({errGetUsers}))
        }
    }
}

export default users