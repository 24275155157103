<template>
<div>
  <section class="main-view">
    <!-- pageLoader -->
    <!-- <pageLoader /> -->
    
    <div class="container-scroller">
      <Header/>
      <div class="container-fluid page-body-wrapper">
        
        <Sidebar/>
        <vue-snotify></vue-snotify>
        <div class="main-panel">
          <!-- bandeau haut de page-->
          <!-- <div v-if="$route.name=='dashboard'" class="privacyPolicy row">
            <div class="col-md-12" v-if="!isHidden">
                <b-alert show>
                   Politique de confidentialité et Cookies. <br>
                  Ce site utilise des cookies fonctionnels et des cookies Google Analytics. <br>
                Les cookies fonctionnels sont indispensables au fonctionnement du site.
                  Les cookies Google Analytics sont destinés à des fins de mesure d'audience, nous aident à identifier le contenu qui vous intéresse le plus ainsi qu'à repérer certains dysfonctionnements du site.
                  En continuant à naviguer, vous nous autorisez à déposer ces cookies.
                  <a href="https://ailem.fr/mentions">En savoir plus</a>
                              <hr>
                    <div class="row">
                      <div class="col-md-5 offset-md-7">
                        <button class="btn btn-secondary" v-on:click="isHidden = true">Refuser</button>
                        <button class="btn btn-primary" v-on:click="isHidden = true">Accepter</button>
                      </div>
                    </div>
                </b-alert>
            </div>
          </div> -->
          <!-- fin bandeau haut de page -->
          <div class="content-wrapper">
            <router-view></router-view>
            <!-- <componentsSidebar/> -->
          </div> <!-- content wrapper ends -->
          <Footer/>
        </div> <!-- main panel ends -->
      </div> <!-- page-body-wrapper ends -->
    </div>
  </section>
</div>
</template>

<script>
  import Header from "./partials/Header";
  import Sidebar from "./partials/Sidebar";
  import Footer from "./partials/Footer";
  import axios from 'axios';
import { refreshAfterInactivityMixin } from '../pages/dashboard/mixins/refresh_after_inactivity';
  // import componentsSidebar from '../components/components-sidebar.vue';
  // import unauthentified from "./../components/unauthentifiedPage"
  // import pageLoader from "./../components/pageLoader"
  export default {
    name: "layout",
    mixins: [refreshAfterInactivityMixin],
    components: {
      Header,
      Sidebar,
      Footer,
      // componentsSidebar
      // unauthentified,
      // pageLoader
    },
    data () {
      return {
        isHidden: false,
        logged: null
      }
    },
    computed:{
      isVisible(){
        var isfund = false
        if (this.$route.name == 'test' || this.$route.name == 'test2'|| this.$route.name == 'test3' || this.$route.name=='page-preview'){
          isfund = true
        }
        return isfund
      }
    },
    methods: {
      getMyInfos(){
        axios.get(`myinfos`)
        .then(resGetMyInfos => {
          localStorage.setItem(`user_ulid`, resGetMyInfos.data.ulid)
        })
        .catch(errGetMyInfos =>{
          if(errGetMyInfos.response.status === 403 || errGetMyInfos.response.status === 401){
            this.$swal({
              title: 'Votre session est expirée, se reconnecter',
              animation: false,
              confirmButtonColor: '#3c8462',
            })
            localStorage.removeItem('token')
            localStorage.removeItem('pseudo')
            this.$router.push({name:'authentification'})
          }
          console.log({errGetMyInfos});
        })
      },
      getCompanies(){
        axios.get(`companies`)
        .then(resCompanies => {
          localStorage.setItem('company_client_ulid', resCompanies.data.result[0].ulid)
        })
        .catch(errCompanies => {
          console.log({errCompanies});
        })
      }
    },
    mounted() {
      //pour vérifier si l'utilisateur est connecté
      this.getMyInfos()
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
        console.log('mitady x-auth-token ny ato anaty src layout');
    },
    created () {
      // this.logged= localStorage.getItem('token')
      this.$store.commit('GET_USER')
      this.$store.dispatch('GetSites')
      // this.$store.dispatch('getProducts')
      this.$store.dispatch('getNotifications')
      this.getCompanies()
    }
  }
</script>