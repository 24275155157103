import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'
import dynamicPage from '../components/layout/type-1'
import adminLayout from '../components/layout/adminLayout'
// import horizontalLayout from '../horizontal-layout'
// import store from '../store/modules/authentification';


Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  // début des routes du routage
  routes: [
    {
      path: '',
      component: {
        render(c) { return c('router-view')}
      },
        children: [
          {
            path: '',
            name: 'authentification',
            component: () => import('@/pages/dashboard/authentification/authentification')
          },
          {
            path: '/login2',
            name: 'login2',
            component: () => import('@/pages/dashboard/authentification/login2')
          }
        ]
    },
    {
      path: '/auth-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import('@/pages/dashboard/authentification/lock-screen')
        },
        {
          path: '/change-password',
          name: 'change-password',
          component: () => import('@/pages/dashboard/authentification/change-password')
        }
      ]
    },
    // Page d'erreur
    {
      path: '/error-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          name: 'error-404',
          component: () => import('@/pages/dashboard/errorPage/error-404')
        },
        {
          path: 'error-500',
          name: 'error-500',
          component: () => import('@/pages/dashboard/errorPage/error-500')
        }
      ]
    },
    {
      path: '/inscription',
      name: 'inscription',
      component: () => import ('@/pages/dashboard/authentification/inscription')
    },
    // page de présentation
    {
      path:'/mock-up',
      name:'mock-up',
      component: () => import('@/pages/dashboard/sites/preview/page-preview-d')
    },

    // Aperçu de la page
    {
      path: '/preview',
      component:layout,
      children: [
        {
          path:'/preview-section-carousel',
          name: 'preview-section-carousel',
          component: () => import ('@/pages/dashboard/metadata/section-carousel')
        }
      ]
    },
    {
      path: '/dashboard',
      component: layout,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/pages/dashboard/dashboard/dashboard')
        },
        
      ]
    },
    {
      path: '/api-services-list',
      component: layout,
      children: [
        {
          // path: ':ulidsite',
          path:'',
          name: 'api-services-list',
          component: () => import('@/pages/dashboard/api-services-list')
        },
        
      ]
    },
    {
      path: '/reservation',
      component: layout,
      children: [
        {
          path: '',
          name: 'reservation',
          component: () => import('@/pages/dashboard/e-commerce/reservation')
        },
        {
          path: 'list',
          name: 'listOfReservations',
          component: () => import('@/pages/dashboard/e-commerce/listOfReservations')
        },
        {
          path: 'details/:ulid',
          name: 'reservationDetails',
          component: () => import('@/pages/dashboard/e-commerce/reservationDetails')
        }
      ]
    },
    {
      path: '/blockdate',
      component: layout,
      children: [
        {
          path: '',
          name: 'blockdate',
          component: () => import('@/pages/dashboard/e-commerce/blockdate')
        },
      ]
    },
    // {
    //   path: '/taxes',
    //   component: layout,
    //   children: [
    //     {
    //       path: '',
    //       name: 'taxes',
    //       component: () => import('@/pages/dashboard/e-commerce/taxes')
    //     },
    //   ]
    // },
    {
      path: '/compagnies',
      component: layout,
      children: [
        {
          path: '',
          name: 'company',
          component: () => import('@/pages/dashboard/company/company')
        },
        {
          path: ':ulid',
          name: 'companyDetails',
          component: () => import('@/pages/dashboard/company/companyDetails')
        },
        {
          path: '/ajouter',
          name: 'addCompany',
          component: () => import('@/pages/dashboard/company/addCompany')
        },
        {
          path: '/edit/:ulid',
          name: 'editCompany',
          component: () => import('@/pages/dashboard/company/addCompany')
        },
        // fermeture de date
        {
          path: 'fermeture/',
          name: 'closing-dates',
          component: () => import('@/pages/dashboard/company/closingDates')
        }
      ]
    },
    // blog/article/posts
    {
      path: '/posts',
      component: layout,
      children: [
        {
          path:'',
          name:'posts',
          component: () => import ('@/pages/dashboard/sites/posts/posts')
        },
        {
          path:'addPost',
          name:'addPost',
          component: () => import ('@/pages/dashboard/sites/posts/addPost')
        },
        {
          path:':ulid',
          name:'postDetails',
          component: () => import ('@/pages/dashboard/sites/posts/postDetails')
        }
      ]
    },
    {
      path: '/sites',
      component:adminLayout,
      children:[
        // Liste de tous les sites
        {
          path:'',
          name: 'sites',
          component: () => import('@/pages/dashboard/sites/website')
        },
        // Page d'ajout d'un site
        {
          path:'addSite',
          name:'addSite',
          component: () => import('@/pages/dashboard/sites/addSite')
        },
        
        {
          path:'editSite/:ulid',
          name:'editSite',
          component: () => import('@/pages/dashboard/sites/addSite')
        },
        {
          path:'edit-text',
          name: 'edit-text',
          component: () => import('@/pages/dashboard/test/edit-text')
        },
        {
          path:'edit-carousel-meb',
          name: 'edit-carousel-meb',
          component: () => import('@/pages/dashboard/test/edit-carousel-meb')
        },
        // Pages d'un site
        {
          path:'/:site/:ulidsite/pages',
          name: 'page',
          component: () => import('@/pages/dashboard/sites/pages')
        },
        // liste des sections d'une page
        {
          path:'/:site/:page/section',
          name: 'section',
          component: () => import('@/pages/dashboard/sites/section')
        },
        {
          path:'/section',
          name: 'sectionBasic',
          component: () => import('@/pages/dashboard/sites/section')
        },
        {
          path: '/carousel/:ulid',
          name: 'add-section-carousel',
          component: () => import ('@/pages/dashboard/sites/sections/add-section-carousel')
        },
        {
          path: '/text/:ulid',
          name: 'add-section-text',
          component: () => import ('@/pages/dashboard/sites/sections/add-section-text')
        },
        {
          path: '/card/:ulid',
          name: 'add-section-card',
          component: () => import ('@/pages/dashboard/sites/sections/add-section-card')
        },
        {
          path: '/cardTitleDescription/:ulid',
          name: 'add-section-card-icon-top',
          component: () => import ('@/pages/dashboard/sites/sections/add-section-card-icon-top')
        },
        {
          path: '/cardIconTop/:ulid',
          name: 'add-section-card-title-description',
          component: () => import ('@/pages/dashboard/sites/sections/add-section-card-title-description')
        },
        {
          path: '/horizontalBanner/:ulid',
          name: 'add-section-horizontal-banner',
          component: () => import ('@/pages/dashboard/sites/sections/add-section-horizontal-banner')
        },
        
        // information d'une section
        {
          path: 'site/page/section/info',
          name: 'sectionInfo',
          component: () => import('@/pages/dashboard/sites/sectionInfo')
        },
        
        // information des pages
        {
          path: 'site_page',
          name: 'sitePage',
          component: () => import ('@/pages/dashboard/sites/site_page')
        },
        {
          path: 'pages',
          name: 'pages',
          component: () => import ('@/pages/dashboard/sites/pages')
        }
      ]
    },
    {
      path: '',
      component: layout,
      children: [
        // page-preview
        {
          path:'/nom_du_site/:page/:ulid',
          name: 'page-preview',
          component: dynamicPage
        },
      ]
    },
    {
      path:'/dynamicContent',
      component:layout,
      children:[
        {
          path:'',
          name:'dynamicContent',
          component:dynamicPage
        }
      ]
    },
    {
      path: '/pageLoader',
      component: layout,
      children: [
        {
          path:'',
          name:'pageLoadercontent',
          component: () => import ('@/pages/dashboard/pageLoader')
        }
      ]
    },
    // test
    {
      path: '/test',
      component: layout,
      children: [
        {
          path: '',
          name: 'test',
          component: () => import('@/pages/dashboard/test/test' )
        },
      ]
    },
    {
      path: '/cpts',
      component: layout,
      children: [
        {
          path: '',
          name: 'cpts',
          component: () => import('@/components/page/cpts-accueil' )
        },
      ]
    },
    // {
    //   path: '/test2',
    //   component: layout,
    //   children: [
    //     {
    //       path: '',
    //       name: 'test2',
    //       component: () => import('@/pages/dashboard/sites/preview/page-preview-edena' )
    //     },
    //   ]
    // },
    {
      path: '/test3',
      component: layout,
      children: [
        {
          path: '',
          name: 'test3',
          component: () => import('@/pages/dashboard/sites/preview/page-preview-d' )
        },
      ]
    },
    {
      path: '/meb-home',
      component: layout,
      children: [
        {
          path: '',
          name: 'meb-home',
          component: () => import('@/pages/dashboard/sites/preview/meb/meb-home' )
        },
      ]
    },
    {
      path: '/construction',
      component: layout,
      children: [
        {
          path: '',
          name: 'construction',
          component: () => import('@/pages/dashboard/construction' )
        },
      ]
    },
    // page des notifications
    {
      path: '/notification',
      component: layout,
      children: [
        {
          path: '',
          name: 'notification',
          component: () => import('@/pages/dashboard/notification' )
        },
      ]
    },
    // fin test
    {
      path: '/galerie',
      component: layout,
      children: [
        {
          path: '',
          name: 'gallery',
          component: () => import('@/pages/dashboard/gallery/gallery' )
        },
        {
          path: 'add-image/:entitytype/:ulid',
          name: 'add-image',
          component: () => import('@/pages/dashboard/gallery/add-image' )
        }
      ]
    },
    {
      path: '/utilisateur',
      component: layout,
      children: [
        {
          path: '',
          name: 'user',
          component: () => import ('@/pages/dashboard/users/user')
        },
        {
          path: 'desactive',
          name: 'disabled_users',
          component: () => import ('@/pages/dashboard/users/disabled_users')
        },
        {
          path: 'ajouter',
          name: 'addUser',
          component: () => import ('@/pages/dashboard/users/addUser')
        },
        {
          path: 'edit/:ulid',
          name: 'editUser',
          component: () => import ('@/pages/dashboard/users/addUser')
        }
      ]
    },
    {
      path: '/achat',
      component: layout,
      children: [
        {
          path: '',
          name: 'purchase',
          component: () => import('@/pages/dashboard/achats/purchase')
        },
        {
          path: 'facture',
          name: 'achatFacture',
          component: () => import ('@/pages/dashboard/achats/achatFacture')
        },
        {
          path: 'commande',
          name: 'achatCommande',
          component: () => import ('@/pages/dashboard/achats/achatCommande')
        },
        {
          path: 'service',
          name: 'achatService',
          component: () => import ('@/pages/dashboard/construction')
          // component: () => import ('@/pages/dashboard/achats/achatService')
        }
      ]
    },
    {
      path:'/information',
      component: layout,
      children: [
        {
          path: 'documentation',
          name: 'documentation',
          component: () => import ('@/pages/dashboard/construction')
          // component: () => import ('@/pages/dashboard/informations/documentation')
        },
        {
          path: 'FAQ',
          name: 'FAQ',
          component: () => import ('@/pages/dashboard/construction')
          // component: () => import ('@/pages/dashboard/informations/FAQ')
        }
      ]
    },
    {
      path: '/eCommerce',
      component: layout,
      children: [
        {
          path: 'facture',
          name: 'facture',
          component: () => import ('@/pages/dashboard/e-commerce/facture')
        },
        {
          path: 'facture/:ulid',
          name: 'detailsFacture',
          component: () => import ('@/pages/dashboard/e-commerce/detailsFacture'),
          props: true
        },
        {
          path: 'commande',
          name: 'commande',
          component: () => import ('@/pages/dashboard/e-commerce/orders')
        },
        {
          path: 'details/:ulid',
          name: 'ordersDetails',
          component: () => import ('@/pages/dashboard/e-commerce/ordersDetails')
        },
        {
          path: 'ensemble',
          name: 'ensemble',
          component: () => import ('@/pages/dashboard/e-commerce/ensemble')
        },
        {
          path: 'catalogue',
          name: 'catalogue',
          component: () => import ('@/pages/dashboard/products/catalogue')
        },
        {
          path: 'catalogue-disabled',
          name: 'catalogue-disabled',
          component: () => import ('@/pages/dashboard/products/catalogue-disabled')
        },
        {
          path: 'catalogue/:ulid',
          name: 'catalogueDetails',
          component: () => import ('@/pages/dashboard/products/CatalogueDetails')
        },
        {
          path: 'editCatalogue/:ulid',
          name: 'editCatalogue',
          component: () => import ('@/pages/dashboard/products/addCatalogue')
        },
        {
          path: 'addCatalogue/',
          name: 'addCatalogue',
          component: () => import ('@/pages/dashboard/products/addCatalogue')
        },
        {
          path: 'vente',
          name: 'ecommerceVente',
          component: () => import ('@/pages/dashboard/e-commerce/vente')
        },
        {
          path: 'agenda',
          name: 'agenda',
          component: () => import ('@/pages/dashboard/e-commerce/agenda')
        },
        {
          path: 'service',
          name: 'service',
          component: () => import ('@/pages/dashboard/products/service')
        },
        {
          path: 'service-archive',
          name: 'service-archive',
          component: () => import ('@/pages/dashboard/products/service-archive')
        },
        {
          path: 'addService',
          name: 'addService',
          component: () => import ('@/pages/dashboard/products/addService')
        },
        {
          path: 'editService/:ulid',
          name: 'editService',
          component: () => import ('@/pages/dashboard/products/addService')
        },

      ]
    },
    {
      path:'/categories',
      component: layout,
      children: [
       {
        path:'',
        name: 'category',
        component: () =>import ('@/pages/dashboard/products/category')
      },
      {
        path: ':ulidCategory',
        name: 'categoryInfo',
        component: () => import ('@/pages/dashboard/products/categoryInfo')
      },
      {
        path:'addCategory',
        name: 'addCategory',
        component: () =>import ('@/pages/dashboard/products/addCategory')
      },
      {
        path:'editCategory/:ulid',
        name: 'editCategory',
        component: () =>import ('@/pages/dashboard/products/addCategory')
      }
      ]
    },
    {
      path:'/panier',
      component: layout,
      children: [
        {
          path:'',
          name:'cart',
          component: () =>import ('@/pages/dashboard/cartItem')
        }
      ]
    },
    //profil de l'utilisateur
    {
      path:'/profil',
      component: adminLayout,
      children: [ {
        path: '',
        name:'profil',
        component: () => import ('@/pages/dashboard/profil')
      }
      ]
    },
    //profil de l'utilisateur
    {
      path:'/subscription',
      component: adminLayout,
      children: [ {
        path: '',
        name:'subscription',
        component: () => import ('@/pages/dashboard/construction')
      }
      ]
    },
    {
      path:'/blank',
      component: layout,
      children: [ {
        path: '',
        name:'blank',
        component: () => import ('@/pages/dashboard/blank')
      }
      ]
    },
    //selection des applications de l'utilisateur
    {
      path:'/applications',
      component: adminLayout,
      children: [ {
        path: '',
        name:'applications',
        component: () => import ('@/pages/dashboard/applications')
      }
      ]
    },
    //redirection
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/dashboard/errorPage/error-404')
        }
      ]
    },
    // {
    //   path: '',
    //   redirect: '/authentification'
    // }
    
    // fin des routes de aile'm
  ]
})

//global redidrection
// router.beforeEach((to, from, next) => {
//   console.log(" isAuthenticated : "+store.state.isAuthenticated)
//   if (to.name !== 'authentification' && !store.state.isAuthenticated) next({ name: 'authentification' })
//   else next()
// })

export default router