import Vue from 'vue'
import App from './App.vue'
// import router from './router/pr'
import router from './router/db'

import axios from 'axios';
// misy an'ito vue-axios mbola tsy hay tsara hoe inona -- à vérifier
// import VueAxios from 'vue-axios';
// const be ='./router/'+ process.env.VUE_APP_ROUTE;
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import ProductZoomer from 'vue-product-zoomer'
import store from './store/index';
import checkView from 'vue-check-view'
import Vuelidate from 'vuelidate'
import AOS from 'aos'
import 'aos/dist/aos.css';
import pageLoader from './components/pageLoader.vue'

import VCalendar from 'v-calendar';
import device from "vue-device-detector"

import Snotify, { SnotifyPosition} from 'vue-snotify'


// axios.defaults.withCredentials = true;
// Vue.use(VueAxios, axios)
Vue.use(ProductZoomer)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(checkView)
Vue.use(Vuelidate)
Vue.use(device)
Vue.component('pageLoader',pageLoader)
Vue.use(VCalendar, {
  //hanaovana "vc-date-picker"
  componentPrefix: 'vc'
});

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
  }
}
Vue.use(Snotify, options)

AOS.init()
Vue.config.Tip = false

// définir le main_api_url selon le node_env
// store.commit('SET_MAIN_API');
// console.log('variable global',store.globalVariable);

// récupérer le backend des sites clients sélectionnés
if(localStorage.getItem('backend')){
  axios.defaults.baseURL=localStorage.getItem('backend')
}
else{
  axios.defaults.baseURL = process.env.VUE_APP_EMERGENCY_URL //url de secours
}
axios.defaults.withCredentials = true

// snotify
axios.interceptors.response.use(function (res) {
  if(res.status === 201){
    Vue.prototype.$snotify.success("Ajouté avec succès");
  }
  else if(res.status === 204){
    Vue.prototype.$snotify.info("Modifié avec succès");
}else{
// Vue.prototype.$snotify.info("Success!!");
}
return res
},

// function (error) {
//   Vue.prototype.$snotify.error(error.message);
//   if (error.response.status === 401) {
//   Vue.prototype.$snotify.error(error.message);
//   alert(`Vous devez vous reconnecter pour accéder à cette page.`)
//   localStorage.clear()
//   router.push('/')
//   return
//   }else{
//   Vue.prototype.$snotify.error(error.message);
//   }
//   if(error.response.status == 403) {
//     alert(`Vous devez vous reconnecter pour accéder à cette page.`)
//     router.push('/')
//   }

//   if(error.response.status >= 500){
//   router.push('/error-pages/error-500')
//   }
//   return Promise.reject(error)
//   }

)


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
