<template>
  <section class="main-view">
    <div class="container-scroller">
      <Header/>
      <!-- <vue-snotify></vue-snotify> -->
      <div class="container-fluid page-body-wrapper">
        <div class="main-panel w-100">
          <!-- <div class="content-wrapper"> -->
            <Content v-if="ulid" :ulid="ulid" :page_name="page" :key="key" />
            <!-- <router-view></router-view> -->
          <!-- </div> content wrapper ends -->
          <hr v-view="onceHandler">
          <Footer class="computer" v-if="displayfooter" />
          <Footer class="mobile"/>
        </div> <!-- main panel ends -->
      </div> <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
  import Header from "./partials/Header";
  import Content from "./../../../pages/dashboard/sites/preview/page-preview-d.vue"
  // import Sidebar from "./partials/Sidebar";
  import Footer from "./partials/Footer";
  export default {
    name: "layout",
    components: {
      Header,
      Footer,
      Content
    },
    data () {
        return {
            displayfooter: false, 
            key:121,
            type:"section",
            page:"",
            ulid:null
        }
    },
    methods:{
      onceHandler(e){
        if(e.percentTop < 1){
          this.displayfooter = true
        }else{
          this.displayfooter = false
        }
      }
    },
    created (){
      console.log("je suis dans components/layout/type-1/index");
    },
    beforeRouteUpdate(to, from, next){
      this.ulid = to.params.ulid
      // this.getProduct(to.params.ulid)
        this.key = this.key + 1
        next()
    },
    mounted() {
      this.ulid = this.$route.params.ulid
      this.page = this.$route.params.page
    }
  }
</script>