<template>
    <section>
        <button class="btn btn-gradient-success btn-icon btn-secondary btn-float" v-b-toggle.components-sidebar>
            <i class="fa fa-angle-double-right"></i>
        </button>

        <span class="d-flex">
            <b-sidebar id="components-sidebar" class="d-flex"  left shadow="" visible>
                <template #header="{hide}">
                    <span  class="w-100 d-flex justify-content-between">
                        <h4>Composants</h4>
                        <button class="btn btn-gradient-success btn-icon" @click="hide"><i class="fa fa-angle-double-left"></i></button>
                    </span>

                </template>
                <div class="px-2">
                    <b-form-group>
                    <b-form-input v-model="search" type="text" placeholder="Recherche"></b-form-input>
                    </b-form-group>
                </div>
                <!-- affichage sans recherche -->
                <div v-if="search==''" class="px-3 py-2">
                    <b-card no-body>
                        <b-tabs pills card vertical fill>
                            <!-- composants -->
                        <b-tab active>
                            <template #title >
                                <i class="fa fa-database"></i>
                            </template>
                            <div class="drag" v-for="(element,elementIndex) in components" :key="elementIndex">
                                <b-card-header  header-tag="header" role="tab">
                                    <span v-b-toggle="'accordion' + elementIndex">
                                        {{element.elementCategory}}
                                    <i class="mdi mdi-menu-up when-opened"></i>
                                    <i class="mdi mdi-menu-down when-closed"></i>
                                    </span>
                                </b-card-header>
                                <b-collapse visible :id="'accordion' + elementIndex" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <draggable
                                        class="row"
                                            :list="element.elementList"
                                            :group="{ name: 'composant', pull: 'clone', put: false }"
                                        >  
                                        <div class="col-md-6 p-1 my-2" v-for="layout in element.elementList" :key="layout.id">
                                            <div class="draggable-item p-1">
                                                <img v-if="layout.elementImage" style="width:100%" :src="require(`../assets/images/import/db/element/${layout.elementImage}`)" alt="">
                                                <img v-else src="@/assets/images/import/db/default-image.jpg" style="width:100%" alt="">
                                                <p class="text-center">{{layout.element}}</p>
                                            </div>
                                        </div>
                                        </draggable>
                                    </b-card-body>
                                </b-collapse>
                            </div>
                        </b-tab>
                        <!-- template -->
                        <b-tab title="template">
                            <template #title>
                                <i class="mdi mdi-file "></i>
                            </template>
                            <div class="drag" v-for="(element,elementIndex) in template" :key="elementIndex">
                                <b-card-header  header-tag="header" role="tab">
                                    <span v-b-toggle="'accordion' + elementIndex">
                                        {{element.elementCategory}}
                                        <i class="mdi mdi-menu-up when-opened"></i>
                                        <i class="mdi mdi-menu-down when-closed"></i>
                                    </span>
                                </b-card-header>
                                <b-collapse :id="'accordion' + elementIndex" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <draggable
                                        class="row"
                                            :list="element.elementList"
                                            :group="{ name: 'composant', pull: 'clone', put: false }"
                                        >  
                                        <div class="col-md-6 p-1 my-2" v-for="layout in element.elementList" :key="layout.id">
                                            <div class="draggable-item p-1">
                                                <img v-if="layout.elementImage" style="width:100%" :src="require(`../assets/images/import/db/element/${layout.elementImage}`)" alt="">
                                                <img v-else src="@/assets/images/import/db/default-image.jpg" style="width:100%" alt="">
                                                <p class="text-center">{{layout.element}}</p>
                                            </div>
                                        </div>
                                        </draggable>
                                    </b-card-body>
                                </b-collapse>
                            </div>
                        </b-tab>
                        <b-tab title="Tab 3"><b-card-text>Tab contents 3</b-card-text></b-tab>
                        </b-tabs>
                    </b-card>
                    
                </div>
                <div v-else class="px-4 py-2">
                    <div v-for="element in elements" :key="element.id">
                        <draggable
                        class="row"
                            :list="element.elementList"
                            :group="{ name: 'composant', pull: 'clone', put: false }"
                        >  
                            <div class="col-md-6 my-2 px-2" v-for="layout in searchElement(element.elementList)" :key="layout.id">
                                <div class="p-1 draggable-item">
                                    <img v-if="layout.elementImage" style="width:100%" :src="require(`../assets/images/import/db/element/${layout.elementImage}`)" alt="">
                                    <img v-else src="@/assets/images/import/db/default-image.jpg" style="width:100%" alt="">
                                    <p class="text-center">{{layout.element}}</p>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </b-sidebar>
        </span>
    </section>
</template>

<script>
import elementAddSection from "../pages/dashboard/json/element-add-section.json"
import template from '../pages/dashboard/json/template.json'
import draggable from "vuedraggable";

export default {
    components: {
        draggable
    },
    display: "Clone",
    order: 2,
    data () {
        return {            
            components: elementAddSection.result,
            search:'',
            template:template.result
        }
    },
    computed:{
        allElements(){
            let elements = [...elementAddSection.result,...template.result]
            // elements.push(elementAddSection.result, template.result)
            // console.log({elements});
            return elements
        }
    },
    props: {

    },
    methods: {
        searchElement(elements){
            const regex = new RegExp(this.search.toLowerCase());
            let result = []
            elements.forEach(el=> {
                if(regex.test(el.element.toLowerCase())){
                    result.push(el)
                }
            })
            return result
        }
    },
    mounted () {
        console.log(this.elements);
    },
    created() {
  },
}
</script>
<style scoped>
::v-deep .nav-item{
    padding:0px !important
}
::v-deep .card  .card-body{
    padding: 0.7rem;
}
.draggable-item {
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.13);
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.13);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.13);
}
.draggable img {
    pointer-events: none;
    user-select: none;
}
/* button */
.btn-float{
    position: fixed;
    z-index: 3000;
    top: 1vh;
    left: 0vh;
    transition: 0.5s;
}
.btn-float.not-collapsed{
    position: fixed;
    z-index: 3000;
    top: 1vh;
    left: -20vh;
}
/* collapse */
.collapsed > .when-opened,
    .not-collapsed > .when-closed {
        display: none;
    }


/* tabs latéral*/
    ::v-deep .nav-pills{
        padding: 0px;
        background: #F2F2F2;
        color: #595959 !important;
    }
    ::v-deep .nav-pills i{
        color: #595959 !important;
        margin: 0px !important;
    }

    ::v-deep .nav-link {
        display: flex;
        justify-content: center;
    }

    ::v-deep .nav-link.active, ::v-deep .nav-link.active i{
        background: #595959;
        color: #F2F2F2 !important;
    }
/* tab interne*/
::v-deep .tab-content{
    padding:0px
}


</style>