<template>
    <section>
         <button
          class="btn btn-gradient-primary btn-sm"
          v-b-modal="modal"
          @click="isClicked()"
        >
          <i class="fa fa-photo"></i>
        </button>
        <b-modal :ref='modal' :id="modal" title="Ajouter une image" size="lg" hide-footer>
            <div class="m-4">
                <b-tabs>
                    <b-tab  title="Sélectionner une image" active>
                    <div  class="row">
                        <div v-if="loader" class="jumping-dots-loader">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <template v-else>
                        <img class="image onfocus" v-for="(image, imageIndex) in images" :key="imageIndex" :src="image.url" :tabindex="imageIndex" @click="selectImage(image)"/>
                        </template>
                    </div>
                    </b-tab>
                    <b-tab title="Nouvelle image" @click="selectedImage ==''">
                        <b-form-group label="Importer l'image" label-for="picture">
                        <b-form-file v-model="file" id="inpu8" :state="Boolean(file)" @change="onFileSelected" placeholder="Choisir ou glisser l'image ici"></b-form-file>
                    </b-form-group>
                    <p>Fichier sélectionné: {{file ? file.name : ''}}</p>
                    </b-tab>
                </b-tabs>
            </div>
            <hr>
            <div class="d-flex justify-content-end">
                <button class="btn btn-link mx-4">Annuler</button>
                <button class="btn btn-success" @click="saveImage()">Associer l'image</button>
            </div>
        </b-modal>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    name: 'add-image',
    data () {
        return {
            images: [],
            loader:false,
            file: null,
            selectedImage: null
        }
    },
    props: {
      modal: String
    },
    methods: {
        getImages() {
        axios.get('imgs')
        .then(resGetImages => {
          let res = resGetImages.data.result
          //simple fitre
          this.images = res.filter(im => im.extension == "webp");
          
          this.loader =false
        })
        .catch(errGetImages => console.error({errGetImages}))
      },
      
      onFileSelected (event) {
          this.file=event.target.files[0]
          },
      async  isClicked(){
        this.loader =true
        this.getImages()
      },
        selectImage (image) {
          this.selectedImage = image
        },
        saveImage () {
          // si la photo est sélectionnée depuis la gallerie
          if(this.selectedImage) {
            this.$emit ('saveImage', {
              image :  this.selectedImage.url,
              ulid :  this.selectedImage.ulid,
              originalImage:this.selectedImage.content.originalImage,
              modalId: this.modal
            })
          }else{
            // si l'on upload une nouvelle photo
            const fd = new FormData
            fd.append ('imgfile', this.file)
            fd.append ('alt', this.file)
            axios.post (
                'imgs', fd, 
            {
              headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(async resSubmitFile=> {
              console.log('apiresponse',resSubmitFile)
              // associer l'image au carousel
              this.$emit ('saveImage', {
                image: resSubmitFile.data.url,
                ulid: resSubmitFile.data.ulid

              })
              alert('Image ajoutée avec succès')
              })
            .catch(err=> console.log(err))
          }

          //comportement du modal et notification
          this.$refs[this.modal].hide()
        }
    },
}
</script>

<style scoped>
.image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 15px;
    width: 22%;
    height: 220px;
    object-fit: cover;
}
@media (max-width: 1024px) {
  .image {
    width: 30%;
    margin: 1.5%;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .image {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }
}
/* changement de couleur lors du focus de l'image */
.onfocus:focus {
    outline: 2px solid blue;
}
</style>